import Layout from "../components/layout"
import { graphql } from "gatsby"
import React from "react"
import { withTrans } from "../withTrans"
import EventsCardsList from "../components/EventsCardsList"
import EventDateHeader from "../components/EventDateHeader"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    site {
      categories: siteMetadata {
        typeOfProgram
      }
    }
    gcms {
      programmes(locales: $locale, orderBy: start_DESC) {
        id
        title
        description
        slug
        cardImage(locales: en) {
          url
        }
        start
        end
        typeOfProgram
      }
    }
  }
`

class Programmes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      programmesData: this.props.data.gcms.programmes,
      filteredItems: this.props.data.gcms.programmes,
    }
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleFilterChangeType = this.handleFilterChangeType.bind(this)
    this.dateWithinRange = this.dateWithinRange.bind(this)
  }
  
  handleFilterChangeType(selectedCheckboxes) {
    let temp = []
    this.state.programmesData
      .filter(item => selectedCheckboxes.indexOf(item.typeOfProgram) > -1)
      .map(filteredItem => temp.push(filteredItem))

    if (temp.length < 1) {
      temp = this.state.programmesData
    }

    this.setState({
      filteredItems: temp,
    })
  }

  handleFilterChange(selectedDates) {
    let temp = []
    if (!selectedDates || selectedDates.length === 0) {
      temp = this.state.programmesData
    } else {
      this.state.programmesData
        .filter(
          item =>
            this.dateWithinRange(selectedDates, item.start) ||
            this.dateWithinRange(selectedDates, item.end)
        )
        .map(filteredItem => temp.push(filteredItem))
      if (temp.length < 1) {
        temp = this.state.programmesData
      }
    }
    this.setState({
      filteredItems: temp,
    })
  }
  dateWithinRange(selectedDates, itemDate) {
    let start = new Date(selectedDates[0]).toISOString().substring(0, 10)
    let end = new Date(selectedDates[1]).toISOString().substring(0, 10)

    if (
      Date.parse(start) <= Date.parse(itemDate) &&
      Date.parse(itemDate) <= Date.parse(end)
    ) {
      return true
    }
  }

  render() {
    const { t } = this.props

    return (
      <Layout>
        <EventDateHeader
          title={t("navbar_title_programmes")}
          categories={this.props.data.site.categories.typeOfProgram}
          onFilterChange={this.handleFilterChange}
          onFilterChangeType={this.handleFilterChangeType}
        />

        <EventsCardsList items={this.state.filteredItems} />
      </Layout>
    )
  }
}

export default withTrans(Programmes)
